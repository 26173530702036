<template lang="pug">
ModalBase.hints(
	closable
	@close="close"
)
	template(#header)
		.title Vocabulary

	template
		ul.list
			li.item
				.item-wrapper
					.subtitle Image Accuracy 
					.description Соответствие изображения в карточке эталону
			li.item
				.item-wrapper
					.subtitle Portfolio Availability
					.description Доля SKU в наличии. Считается как отношение количества SKU in stock к общему количеству SKU в матрице маркета
			li.item
				.item-wrapper
					.subtitle PSO Total
					.description PDS отчёт по всем маркетам, категориям и брендам
			li.item
				.item-wrapper
					.subtitle PSO Details
					.description PDS отчёт по одному маркету, всем его категориям и брендам
			li.item
				.item-wrapper
					.subtitle Raiting&Reviews
					.description Рейтинг и количество отзывов в карточке SKU
			li.item
				.item-wrapper
					.subtitle Share of Search
					.description Доля SKU в топ-10 поисковой выдаче. Чем выше позицию занимает SKU, тем больше у неё вес
			li.item
				.item-wrapper
					.subtitle Taxonomy
					.description Доля SKU в топе категорийной выдачи

</template>

<script>
import ModalBase from '@/components/Layout/ModalBase.vue'
import IdeaIcon from '@/assets/svg/idea.svg'

export default {
	components: {
		ModalBase,
		IdeaIcon,
	},
	methods: {
		close() {
			this.$emit('close');
		},
	}
}
</script>

<style lang="scss" scoped>
.hints {
	width: 568px;
	padding-top: 24px;
	padding-right: 24px;
	padding-bottom: 32px;
	padding-left: 40px;
}
.title {
	font-size: 24px;
}
.list {
	margin-left: 20px;
}
.item {
	display: flex;
	&:before {
		content: "";
		background-color: #BEBEBE;
		margin-left: -1em;
		width: 6px;
		height: 6px;
		border-radius: 50%;
		display: flex;
		position: absolute;
		margin-top: 5px;
	}
	&-wrapper {
		display: flex;
		flex-direction: column;
	}
	&:not(:last-of-type) {
		margin-bottom: 24px;
	}
}
.subtitle {
	font-weight: 600;
	color: #212121;
}
.description {
	font-size: 12px;
	color: #3D3D3D;
}
</style>